import React from 'react';

import Markdown from 'components/common/markdown/Markdown';
import pageSectionsHook from 'hooks/page-sections/page-sections.hook';
import { getItemsByPage } from 'utils/directus';
import { Props, PageSection } from './PageSections.interface';

import { pageSections, pageSections__section } from './PageSections.scss';

export default ({ slug }: Props): JSX.Element => {
  const sections = getItemsByPage(slug, pageSectionsHook());
  return (
    <div className={pageSections}>
      {sections.map(({ node }: PageSection) => (
        <div key={`page-${node.page.slug}-section-${node.directusId}`} className={pageSections__section}>
          <Markdown source={node.content} container />
        </div>
      ))}
    </div>
  );
};
